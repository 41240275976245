import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportPaymentStatus = (props) => {
  const serverpage = "reportpaymentstatus"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [supplierList, setSupplierList] = useState(null);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedSupplierId, setSelectedSupplierId] = useState(0);
  // const [selectedBillingById, setSelectedBillingById] = useState(0);
  const [SubHeader, setSubHeader] = useState({
    NetPaymentAmount: 0,
    PaymentAmount: 0,
    DuePayment: 0
  });
  
  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const PrintPDFExcelExportFunction = (reportType) => {
    const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
    let finalUrl = EXCEL_EXPORT_URL + "report/payment_status_report_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        serverpage +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&SupplierId=" +
        selectedSupplierId +
        "&SubHeader=" +
        JSON.stringify(SubHeader) +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      // TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
      SupplierId: selectedSupplierId,
      // UserId: selectedBillingById,
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    getSupplierList();
    getDataList(); //invoice list
    setBFirst(false);
  }

  function getSupplierList() {
    let params = {
      action: "SupplierList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setSupplierList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Rec date",
      width: "6%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InvoiceNo",
      label: "Rec Inv No",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "ChallanNo",
      label: "Supplier Invoice No",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "SupplierName",
      label: "Supplier Name",
      // width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "NetPaymentAmount",
      label: "Net Payment Amount",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "PaymentAmount",
      label: "Paid Amount",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "PaymentStatus",
      label: "Status",
      width: "5%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "DuePayment",
      label: "Due Payment",
      width: "10%",
      align: "right",
      type: "number",
      visible: true,
      sort: true,
      filter: true,
    },


  ];

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const handleChangeFilterDropDown = (name, value) => {
    if (name == "SupplierId") {
      setSelectedSupplierId(value);
    } 
    // else if (name == "UserId") {
    //   setSelectedBillingById(value);
    // }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate, selectedSupplierId]);

  useEffect(() => {
    let NetPaymentAmount = 0,
    PaymentAmount = 0,
    DuePayment = 0;

    dataList.forEach((row) => {
      // console.log('row: ', row);
      NetPaymentAmount += row.NetPaymentAmount;
      PaymentAmount += row.PaymentAmount;
      DuePayment += row.DuePayment;
    });

    NetPaymentAmount = new Intl.NumberFormat(["eng", "id"]).format(NetPaymentAmount);
    PaymentAmount = new Intl.NumberFormat(["eng", "id"]).format(PaymentAmount);
    DuePayment = new Intl.NumberFormat(["eng", "id"]).format(DuePayment);

    setSubHeader({
      NetPaymentAmount: NetPaymentAmount,
      PaymentAmount: PaymentAmount,
      DuePayment: DuePayment,
    });
  }, [dataList]);


  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Payment Status
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        <div class="searchAdd">
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <div>
            <label class="pl-10">Supplier</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="SupplierId"
              name="SupplierId"
              autoComplete
              options={supplierList ? supplierList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "SupplierId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          {/* <div>
            <label class="pl-10">Billing Person</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="UserId"
              name="UserId"
              autoComplete
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "UserId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div> */}

          {/* <Button disabled={permissionType} label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button
            label={"Export"}
            class={"btnPrint"}
            onClick={PrintPDFExcelExportFunction}
          />
        </div>


        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
          Net Payment Amount: <span class="font-bold">{SubHeader.NetPaymentAmount}</span>
          </label>
          <label class="pl-10">
          Paid Amount: <span class="font-bold">{SubHeader.PaymentAmount}</span>
          </label>
          <label class="pl-10">
          Due Payment: <span class="font-bold">{SubHeader.DuePayment}</span>
          </label>


        </div>

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={masterColumnList}
              rows={dataList ? dataList : {}}
              // actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPaymentStatus;
