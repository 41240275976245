import React, { forwardRef, useRef, useContext, useEffect } from "react";
import swal from "sweetalert";
import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { Button } from "../../../components/CustomControl/Button";
import moment from "moment";

import { Typography, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Virtualize } from "../../../components/CustomControl/Virtualize";

import CustomTable from "components/CustomTable/CustomTable";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const ReportAttendanceSheet = (props) => {
  const serverpage = "reportattendancesheet"; // this is .php server page
  const permissionType = props.permissionType;

  const { useState } = React;
  const [bFirst, setBFirst] = useState(true);
  const [attendancePersonList, setAttendancePersonList] = useState(null);
  const [StartDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedPersonnelID, setSelectedPersonnelID] = useState(0);
  // const [selectedBillingById, setSelectedBillingById] = useState(0);
  const [SubHeader, setSubHeader] = useState({
    SubTotalAmount: 0,
    VatAmount: 0,
    DiscountAmount: 0,
    TotalAmount: 0,
    Commission: 0,
  });
  
  
  
  
  
  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data master list

  const UserInfo = LoginUserInfo();

  /* =====Start of Excel Export Code==== */
  const PrintPDFExcelExportFunction = (reportType) => {
    const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=AttendanceSheetExport" +
        "&reportType=excel" +
        "&ClientId=" +
        UserInfo.ClientId +
        "&BranchId=" +
        UserInfo.BranchId +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&PersonnelID=" +
        selectedPersonnelID +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

 
  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
      // TransactionTypeId: 2,
      StartDate: StartDate,
      EndDate: EndDate,
      PersonnelID: selectedPersonnelID, 
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  if (bFirst) {
    /**First time call for datalist */
    getAttendancePersonList();
    getDataList(); //invoice list
    setBFirst(false);
  }

  function getAttendancePersonList() {
    let params = {
      action: "AttendancePersonList",
      lan: language(),
      UserId: UserInfo.UserId,
      ClientId: UserInfo.ClientId,
      BranchId: UserInfo.BranchId,
    };

    apiCall.post("combo_generic", { params }, apiOption()).then((res) => {
      setAttendancePersonList([{ id: 0, name: "All" }].concat(res.data.datalist));
    });
  }

  const masterColumnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "TransactionDate",
      label: "Date And Time",
      width: "10%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "PersonnelID",
      label: "Personnel ID",
      width: "6%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "FirstName",
      label: "First Name",
      width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "LastName",
      label: "Last Name",
      width: "8%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "CardNumber",
      label: "Card Number",
      width: "7%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "DeviceName",
      label: "Device Name",
      width: "6%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "EventPoint",
      label: "Event Point",
      width: "7%",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "VerifyType",
      label: "Verify Type",
      width: "7%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "InOutStatus",
      label: "In/Out Status",
      width: "6%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },

    {
      field: "EventDescription",
      label: "Event Description",
      width: "10%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },
    {
      field: "RemarksText",
      label: "Remarks",
      // width: "5%",
      align: "left",
      // type: "number",
      visible: true,
      sort: true,
      filter: true,
    },


  ];

  const handleChangeFilterDate = (e) => {
    const { name, value } = e.target;
    console.log("value: ", value);
    console.log("name: ", name);
    if (name === "StartDate") {
      setStartDate(value);
    }

    if (name === "EndDate") {
      setEndDate(value);
    }
    // let data = { ...currentInvoice };
    // data[name] = value;
  };

  const handleChangeFilterDropDown = (name, value) => {
    if (name == "PersonnelID") {
      setSelectedPersonnelID(value);
    } 
    // else if (name == "UserId") {
    //   setSelectedBillingById(value);
    // }
  };

  useEffect(() => {
    console.log("useEffect call");
    getDataList();
  }, [StartDate, EndDate, selectedPersonnelID]);

  // useEffect(() => {
    // let SubTotalAmount = 0,
    // VatAmount = 0,
    // DiscountAmount = 0,
    // TotalAmount = 0,
    // Commission = 0;

    // dataList.forEach((row) => {
    //   // console.log('row: ', row);
    //   SubTotalAmount += row.SubTotalAmount;
    //   VatAmount += row.VatAmount;
    //   DiscountAmount += row.DiscountAmount;
    //   TotalAmount += row.TotalAmount;
    //   Commission += row.Commission;
    // });

    // SubTotalAmount = new Intl.NumberFormat(["eng", "id"]).format(SubTotalAmount);
    // VatAmount = new Intl.NumberFormat(["eng", "id"]).format(VatAmount);
    // DiscountAmount = new Intl.NumberFormat(["eng", "id"]).format(DiscountAmount);
    // TotalAmount = new Intl.NumberFormat(["eng", "id"]).format(TotalAmount);
    // Commission = new Intl.NumberFormat(["eng", "id"]).format(Commission);

    // setSubHeader({
    //   SubTotalAmount: SubTotalAmount,
    //   VatAmount: VatAmount,
    //   DiscountAmount: DiscountAmount,
    //   TotalAmount: TotalAmount,
    //   Commission: Commission,
    // });
  // }, [dataList]);


  return (
    <>
      <div class="bodyContainer">
        <div class="topHeader">
          <h4>
            <a href="#">Home</a> ❯ Reports ❯ Attendance Sheet
          </h4>
        </div>

        {/* <!-- TABLE SEARCH AND GROUP ADD --> */}
        <div class="searchAdd">
          <label class="pl-10">Start Date</label>
          <div>
            <input
              type="date"
              id="StartDate"
              name="StartDate"
              value={StartDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <label class="pl-10">End Date</label>
          <div>
            <input
              type="date"
              id="EndDate"
              name="EndDate"
              value={EndDate}
              onChange={(e) => handleChangeFilterDate(e)}
            />
          </div>

          <div>
            <label class="pl-10">User</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="PersonnelID"
              name="PersonnelID"
              autoComplete
              options={attendancePersonList ? attendancePersonList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "PersonnelID",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div>

          {/* <div>
            <label class="pl-10">Billing Person</label>
          </div>
          <div class="">
            <Autocomplete
              autoHighlight
              disableClearable
              className="chosen_dropdown"
              id="UserId"
              name="UserId"
              autoComplete
              options={userList ? userList : []}
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: "All" }}
              onChange={(event, valueobj) =>
                handleChangeFilterDropDown(
                  "UserId",
                  valueobj ? valueobj.id : ""
                )
              }
              renderOption={(option) => (
                <Typography className="chosen_dropdown_font">
                  {option.name}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="standard" fullWidth />
              )}
            />
          </div> */}

          {/* <Button disabled={permissionType} label={"ADD"} class={"btnAdd"} onClick={addData} /> */}
          <Button
            label={"Export"}
            class={"btnPrint"}
            onClick={PrintPDFExcelExportFunction}
          />
        </div>

{/* 
        <div class="rpt-subheader searchAdd">
          <label class="pl-10">
          SubTotal: <span class="font-bold">{SubHeader.SubTotalAmount}</span>
          </label>
          <label class="pl-10">
            VAT: <span class="font-bold">{SubHeader.VatAmount}</span>
          </label>
          <label class="pl-10">
            Discount: <span class="font-bold">{SubHeader.DiscountAmount}</span>
          </label>
          <label class="pl-10">
            Total: <span class="font-bold">{SubHeader.TotalAmount}</span>
          </label>
          <label class="pl-10">
          Commission: <span class="font-bold">{SubHeader.Commission}</span>
          </label>


        </div> */}

        <div class="subContainer">
          <div className="App">
            <CustomTable
              columns={masterColumnList}
              rows={dataList ? dataList : {}}
              // actioncontrol={actioncontrolmaster}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportAttendanceSheet;
